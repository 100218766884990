<template>
  <div style="display: flex;flex-direction: column;height: 100%">
    <router-view :class="{'container_components': storeAuth.isAuthenticated}"></router-view>
    <ComponentFooter v-if="storeAuth.isAuthenticated"></ComponentFooter>
  </div>
</template>

<script>

import ComponentFooter from "@/components/footer/ComponentFooter.vue";
import {useAuthStore} from "@/stores/auth";
import axios from "axios";
import {useI18n} from "vue-i18n";

export default {
  name: 'App',
  components: {ComponentFooter},
  setup() {
    const storeAuth = useAuthStore()
    return {
      storeAuth
    }
  },
  mounted() {
    document.addEventListener('contextmenu', function (event) {
      // Verifica se l'elemento cliccato è un'immagine
      if (event.target.tagName === 'IMG') {
        event.preventDefault();  // Blocca il menu contestuale
      }
    });

    axios.defaults.headers["Accept-Language"] = useI18n().locale.value

    if (useAuthStore().isAuthenticated) {
      axios.defaults.headers['Authorization'] = 'Bearer ' + useAuthStore().userGetters.token
    }

    window.addEventListener('resize', this.handleResize)
    let app = document.getElementById('app')
    if (app) {
      let vh = window.innerHeight * 0.01;
      let widgetHeight = vh * 100
      app.style.height = widgetHeight + 'px'
    }
  },
  methods: {
    handleResize() {
      let app = document.getElementById('app')
      if (app) {
        let vh = window.innerHeight * 0.01;
        let widgetHeight = vh * 100
        app.style.height = widgetHeight + 'px'
      }
    }
  },
  updated() {
    let app = document.getElementById('app')
    if (app) {
      let vh = window.innerHeight * 0.01;
      let widgetHeight = vh * 100
      app.style.height = widgetHeight + 'px'
    }
  },
  beforeUnmount() {
    document.removeEventListener('contextmenu', function () {
    })
    window.removeEventListener('resize', this.handleResize)
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style>
.container_components.container-components {
  height: calc(100% - 100px) !important;
}

#app {
  height: 100vh;
}
</style>
