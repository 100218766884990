<template>
  <div class="container-footer">
    <router-link to="/home" :class="{'btn_red_custom_mini_not_active': $route.name !== 'detailNews' && $route.name !== 'home'}" class="btn btn-red-custom btn-red-custom-mini">
      <img v-show="$route.name === 'home' || $route.name === 'detailNews'" src="../../assets/footer/home-white.svg" alt="">
      <img v-show="$route.name !== 'home' && $route.name !== 'detailNews'" src="../../assets/footer/home-red.svg" alt="">
    </router-link>
    <router-link to="/map" :class="{'btn_red_custom_mini_not_active': $route.name !== 'map'}" class="btn btn-red-custom btn-red-custom-mini">
      <img v-show="$route.name === 'map'" src="../../assets/footer/map-white.svg" alt="">
      <img v-show="$route.name !== 'map'" src="../../assets/footer/map-red.svg" alt="">
    </router-link>
    <router-link to="/scanner" :class="{'btn_red_custom_mini_not_active': $route.name !== 'scanner'}" class="btn btn-red-custom btn-red-custom-mini">
      <img v-show="$route.name === 'scanner'" src="../../assets/footer/scan-white.svg" alt="">
      <img v-show="$route.name !== 'scanner'" src="../../assets/footer/scan-red.svg" alt="">
    </router-link>
    <router-link to="/profile" style="margin-right: 0px!important;" :class="{'btn_red_custom_mini_not_active': $route.name !== 'profile'}" class="btn btn-red-custom btn-red-custom-mini">
      <img v-show="$route.name === 'profile'" src="../../assets/footer/user-white.svg" alt="">
      <img v-show="$route.name !== 'profile'" src="../../assets/footer/user-red.svg" alt="">
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ComponentFooter',
  components: {},
  setup() {
    return {}
  },
  data() {
    return {}
  },
  created() {
  },
  watch: {},
  computed: {},
  mounted() {
  },
  methods: {},
  updated() {
  },
  beforeUnmount() {
  },
  unmounted() {
  }
}
</script>


<style scoped>
.btn_red_custom_mini_not_active.btn-red-custom-mini:hover {
  background: transparent !important;
  border: 1px solid var(--color-red) !important;
  color: var(--color-red) !important;
}

.btn_red_custom_mini_not_active.btn-red-custom-mini:active {
  background: transparent !important;
  border: 1px solid var(--color-red) !important;
  color: var(--color-red) !important;
}

.btn_red_custom_mini_not_active.btn-red-custom-mini {
  background: transparent !important;
  border: 1px solid var(--color-red) !important;
  color: var(--color-red) !important;
}

.btn-red-custom-mini {
  width: 50px !important;
  height: 50px !important;
  padding: 0px !important;
  margin-right: auto !important;
}

.container-footer {
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 1;
  border-top-left-radius: 10px;
  background: var(--color-white);
  height: 100px;
  padding: 20px;
  border-top-right-radius: 10px;
}

/*// X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {

}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}
</style>