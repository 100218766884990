import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router"
import axios from "axios"
import {createPinia} from 'pinia'
import {createPersistedStatePlugin} from 'pinia-plugin-persistedstate-2'
import {vBTooltip, vBPopover, BootstrapVueNext} from "bootstrap-vue-next";
import './assets/css/main.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import moment from "moment";
import {createI18n} from "vue-i18n";
// eslint-disable-next-line no-unused-vars
import {languages} from "@/langs/languages";
import {langs} from "@/langs";
import {useAuthStore} from "@/stores/auth";

const pinia = createPinia()
moment.locale('it')
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST


axios.interceptors.response.use(undefined, function (error) {
    if (error.response) {
        if (error.response.status === 404)
            router.push('/').catch(() => {
            })
        if (error.response.status === 401) {
            useAuthStore().$reset()
            let string_split
            Object.keys(error.response.data).forEach(el => {
                string_split = el.split('-')
                if (string_split[1] === 'expired_token') {
                    useAuthStore().errorToken = 'Sessione scaduta'
                } else if (string_split[1] === 'invalid_token') {
                    useAuthStore().errorToken = 'Rieffettuare il login'
                } else if (el === 'code-invalid_code') {
                    useAuthStore().errorToken = error.response.data['code-invalid_code']
                } else if (el === 'authorization-user_inactive') {
                    useAuthStore().errorToken = error.response.data['authorization-user_inactive']
                } else if (el === 'authorization-expired_token') {
                    useAuthStore().errorToken = error.response.data['authorization-expired_token']
                } else if (el === 'detail') {
                    useAuthStore().errorToken = 'Qualcosa non ha funzionato oppure non sei autorizzato'
                } else if (el === 'error') {
                    useAuthStore().errorToken = 'Non autorizzato'
                }
            })
            router.push('/login').catch(() => {
            })
            return Promise.reject(error);
        }
    }
    // if (error.message === 'Network Error') {
    //     useApiSettingStore().overlayModal = false
    //     useApiSettingStore().errorNetwork = true
    // }
    return Promise.reject(error);
})

const i18n = createI18n({
    legacy: false,
    // locale: 'it',
    locale: languages[navigator.language],
    fallbackLocale: 'it',
    messages: langs
});

const app = createApp(App)
app.directive('b-tooltip', vBTooltip)
app.directive('b-popover', vBPopover)
app.use(router)
app.use(i18n)
app.use(BootstrapVueNext)
app.use(pinia)
pinia.use(createPersistedStatePlugin())
app.mount('#app')
